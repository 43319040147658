<template>
  <div>
    <b-modal
      id="price-paid-modal"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('PaymentDetailsFor')} ${order}`"
      hide-footer
      size="xl"
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <template>
          <b-tab
            v-for="material in getOrderItemPaymentByOrderNumber"
            :key="material.orderItemId"
          >
            <template #title>
              <strong
                style="font-weight: 500; color: black"
              >{{ material.articleNumber }}</strong>
            </template>
            <b-card-text>
              <div style="margin: 10px 0px;">
                <b-form-group
                  :label="`${$t('Price')}:`"
                  style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
                >
                  <div style="display: flex; justify-content: flex-start; align-items: center;">
                    <b-input
                      v-model="material.realPrice"
                      disabled
                      type="number"
                    />
                    <!-- <p>{{ material.price + material.discount }}</p> -->
                  </div>
                </b-form-group>
                <b-form-group
                  v-if="material.discount>0"
                  :label="`${$t('Discount')}:`"
                  style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
                >
                  <div style="display: flex; justify-content: flex-start; align-items: center;">
                    <b-input
                      v-model.number="material.discount"
                      disabled
                      type="number"
                    />
                  </div>
                </b-form-group>
                <b-form-group
                  v-if="material.discount>0"
                  :label="`${$t('PriceDiscount')}:`"
                  style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
                >
                  <div style="display: flex; justify-content: flex-start; align-items: center;">
                    <b-input
                      v-model.number="material.priceWithDiscount"
                      disabled
                      type="number"
                    />
                  </div>
                </b-form-group>
                <b-form-group
                  :label="`${$t('PaidPrice')}:`"
                  style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
                >
                  <div style="display: flex; justify-content: flex-start; align-items: center;">
                    <b-input
                      v-model.number="material.paidAmount"
                      disabled
                      type="number"
                    />
                  </div>
                </b-form-group>
                <b-form-group
                  :label="`${$t('RemainingAmount')}:`"
                  style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
                >
                  <div style="display: flex; justify-content: flex-start; align-items: center;">
                    <b-input
                      v-model="material.remaining"
                      disabled
                      type="number"
                    />
                    <!-- <p>{{ material.price + material.discount }}</p> -->
                  </div>
                </b-form-group>
              </div>
              <div style="display: flex; flex-wrap: wrap;">
                <div
                  v-for="item in material.orderItemPaymentTrackResponseDtos"
                  :key="item.id"
                  style="width: calc(33.33% - 10px); border: 1px solid lightgray; padding: 20px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: none; border-radius: 6px; opacity: 1; margin: 0 5px 0px 0;"
                >
                  <div style="display: flex; justify-content: space-between;">
                    <button style="width: 100%; background: #FF274F; border: none; color: white; border-radius: 5px; border: none;">
                      <!-- {{ $t(monthToString(item.month)) }} -->
                      {{ formatDate(item.created) }}
                    </button>
                  </div>
                  <hr style="color: rgb(146, 146, 146);">
                  <!-- <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                    <b>{{ $t("ExecutorName") }}</b>
                    <b>{{ $t("Amount") }}</b>
                  </div>
                  <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                    <p style="margin-bottom: 2px;">
                      {{ item.fullName }}
                    </p>
                    <p style="margin-bottom: 2px;">
                      {{ item.amount }}€
                    </p>
                  </div> -->
                  <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                    <b style="color: #262E6C;">{{ $t("ExecutorName") }}</b>
                    <p style="margin-bottom: 2px;">
                      {{ item.fullName }}
                    </p>
                  </div>
                  <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                    <b style="color: #262E6C;">{{ $t("Amount") }}</b>
                    <p style="margin-bottom: 2px;">
                      {{ numbersWithcomma(item.amount) }} {{ item.currency }}
                    </p>
                  </div>
                  <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                    <b style="color: #262E6C;">{{ $t('BankAccount') }}</b>
                    <p style="margin-bottom: 2px;">
                      {{ item.accountName }}
                    </p>
                  </div>
                  <div style="border: 2px solid lightgrey; padding: 5px; width: 100%;">
                    <b>{{ $t("Notes") }}</b>
                    <p>{{ item.note }}</p>
                  </div>
                </div>
                <!-- style="display: flex; flex-direction: column;align-items: center;" -->
                <div
                  v-if="showTemplate"
                  style="width: 30%;"
                >
                  <div
                    style="width:100%;border: 1px solid lightgray; padding: 20px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: none; border-radius: 3px; opacity: 1; margin:0 5px;"
                  >
                    <div style="display: flex; justify-content: space-between;">
                      <button style="width: 100%;background: #FF274F; border: none; color: white; border-radius: 5px; border: none;">
                        <!-- {{ $t(monthToString(item.month)) }} -->
                        <!-- {{ formatDate(item.created) }} -->
                        {{ realTime }}
                      </button>
                    </div>
                    <hr style="color: rgb(146, 146, 146);">
                    <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                      <!-- <b style="color: #262E6C;">{{ $t("ExecutorName") }}</b> -->
                      <b style="color: #475467; font-weight: 500;">{{ $t("Amount") }}</b>
                    </div>
                    <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">

                      <b-input
                        v-model="input1"
                        type="number"
                      />
                    </div>
                    <div style="display: flex; width: 100%">
                      <b-form-group
                        id="input-group-8"
                        :label="$t('Account')"
                        style="width:100%"
                        label-for="input-25"
                      >
                        <vue-select
                          id="input-1"
                          v-model="accountId"
                          :reduce="(e) => e.accountId"
                          label="name"
                          :placeholder="$t('Select')"
                          :options="getAccountDetails"
                        />
                      </b-form-group>
                      <b-form-group
                        id="input-group-8"
                        :label="$t('Currency')"
                        style="width: 100%"
                        label-for="input-25"
                      >
                        <vue-select
                          v-model="currency"
                          dropup
                          required
                          :options="currencies"
                          style="width: 100%"
                        />
                      </b-form-group>
                    </div>
                    <div style="width: 40%; padding: 5px; width: 100%;">
                      <b style="color: #475467; font-weight: 500;">{{ $t("Notes") }}</b>
                      <!-- <p>{{ item.note }}</p> -->
                      <!-- <b-input-textarea
                        v-model="input2"
                        type="text"
                      /> -->
                      <b-form-textarea
                        id="textarea"
                        v-model="input2"
                        rows="3"
                        max-rows="6"
                      />

                    </div>
                  </div>
                  <div class="buttonsEverywhere">
                    <!-- <b-button @click="addNewDate(input1, input2)">
                      Submit
                    </b-button>
                    <b-button @click="addNewDate(input1, input2)">
                      Cancel
                    </b-button> -->
                    <b-button
                      :disabled="input1 == null || input1 == ''"
                      type="button"
                      variant="none"
                      class="buttonSubmit"
                      style="margin-left: 5px; background: #FF274F !important; color: white;"

                      @click="addNewDate(input1, input2)"
                    >
                      {{ $t('Submit') }}
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="buttonCancel"
                      style="background: white; color: black; border-color: white;font-weight: 400;"

                      @click="onCancel"
                    >
                      {{ $t('Cancel') }}
                    </b-button>
                  </div>
                </div>
              </div>
              <button
                v-if="!showTemplate && !$route.path.includes('clients')"
                type="button"
                class="button"
                size="sm"
                style="margin: 10px 5px; border-radius: 6px;"
                @click="showInputTemplate"
              >
                <p style="margin: 0">
                  + {{ $t('AddPayment') }}
                </p>
              </button>
            </b-card-text>
          </b-tab>
        </template>

      </b-tabs></b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import moment from 'moment'
// import vuegalleryslideshow from 'vue-gallery-slideshow';
import {
} from 'vuelidate/lib/validators';
import 'vue2-datepicker/index.css';
import { client } from '../../../domainConfig'


// import {
//   required, dateFormat, minLength, alpha, minValue, numeric, alphaNum, email,
// } from 'vuelidate/lib/validators';

export default {
  components: {
    // vuegalleryslideshow,
    // flatPickr,
    // DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
  props: ['order-item-id', 'order', 'year'],
  data() {
    return {
      currencies: ['€', '$', 'CHF'],
      client: client.clientName,
      index: null,
      input1: null,
      input2: null,
      accountId: null,
      currency: null,
      showFrontBody: true,
      showTemplate: false,
      showFittingError: false,
      realPrice: null,
      remaining: null,
      showSubmit: true,
      selectedCountry: null,
      editProbeName: false,
      countryOptions: [
        'Switzerland',
        'Germany',
        'France',
        'Austria',
        'Kosovo',
        'Albania',
        'North Macedonia',
        'Serbia',
        'Croatia', // Kosovo's ISO 3166-1 alpha-2 code is 'XK'
      ],
      tabIndex: 1,
      probeDate: {},
      form: [],
      prices: {
        paidAmount: null,
        price: null,
        orderItemId: null,
      },
      location: {
        locationName: null,
        locationId: null,
      },
    };
  },
  validations: {
  },
  computed: {
    ...mapGetters([
      'getProbeDates',
      'getimagesOrder',
      'getPrices',
      'getTwentyPrinciples',
      'getMaterialVariantsOfOrder',
      'getDescriptionOfOrder',
      'getOrderItemSize',
      'getLoggedInUser',
      'getOrderItemLocatioon',
      'getOrderItemPaymentByOrderNumber',
      'getAccountDetails',
    ]),
    realTime() {
      return moment().format('DD-MM-YYYY hh:mm')
    },
  },
  watch: {
    form: {
      deep: true,
      handler(newForm) {
      // Check if all probeDates are filled
        const allProbeDatesFilled = newForm.every(({ date }) => { return date !== '' });

        if (allProbeDatesFilled) {
          this.showFittingError = false;
        }
      },
    },
    getProbeDates() {
      this.fillForm();
    },
    getOrderItemPaymentByOrderNumber(value) {
      console.log('value', value)
      this.form = value

      for (let i = 0; i < value.length; i++) {
        console.log('valueee', value[i].price)
        // this.realPrice = value[i].price + value[i].discount

        value[i].realPrice = value[i].price;
        value[i].priceWithDiscount = value[i].price - value[i].discount
        value[i].remaining = value[i].priceWithDiscount - value[i].paidAmount;
        this.remaining = value[i].priceWithDiscount - value[i].paidAmount
      }
    },

    getPrices() {
      this.fillPrices();
    },
    getOrderItemLocatioon() {
      this.fillLocation();
    },
    'prices.paidAmount': {
      immediate: true,
      handler(newVal) {
        if (newVal > this.prices.price) {
          this.showSubmit = false;
        } else {
          this.showSubmit = true;
        }
      },
    },
  },
  mounted() {
    this.accountDetails({ isActive: true, bankId: null })
  },
  methods: {
    ...mapActions(['accountDetails', 'resetOrderItemTrack', 'getOrdersOverview', 'loadOrderItemPaymentTrackByOrderNumber', 'addOrderItemPaymentTrack', 'loadCountryStates', 'editPaidAmount', 'editOrAddProbeDate', 'delete_probeDate', 'loadProbeDates', 'loadPrices']),
    showInputTemplate() {
      this.showTemplate = true;
    },
    addNewDate(input1, input2) {
      // Here, you can use the values of input1 and input2 as needed.
      // You can perform any action or store data as required.
      const order = this.getOrderItemPaymentByOrderNumber[this.tabIndex].orderItemId
      console.log('order:', order);
      console.log('Input 1:', input1);
      console.log('Input 2:', input2);

      this.addOrderItemPaymentTrack({
        object: {
          orderItemId: order,
          note: input2,
          amount: input1,
          accountId: this.accountId,
          currency: this.currency,
        },
        successCallback: () => {
          this.loadOrderItemPaymentTrackByOrderNumber({ orderNumber: this.order })
          this.getOrdersOverview({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
            pickUpDate: null,
            salesType: this.$route.path == '/materials-overview' ? 'Material' : 'Product',
            orderType: null,
            orderItemState: null,
            year: this.year,
          })
        },
      })

      // Reset the inputs and hide the template after processing
      this.input1 = '';
      this.input2 = '';
      this.accountId = '';
      this.accountId = '';
      this.currency = false;
      // this.$refs.modal.hide();
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    async onSubmit() {
      const hasEmptyProbeDate = this.form.some(({ date }) => { return !date });

      if (hasEmptyProbeDate) {
        this.showFittingError = true
        return;
      }
      this.$refs.modal.hide();
      const updatedArray = this.form.map(({
        editable, probeDate, probeId, ...rest
      }) => {
        return { probeDateId: probeId, ...rest };
      });

      this.editOrAddProbeDate({
        object: updatedArray,
        successCallback: () => {
          // Your success callback logic here
        },
      });
      this.onReset();
      this.editProbeName = false;
    },
    onSubmitPrice(orderId, amount, note, accountId, accountName, currency) {
      this.addOrderItemPaymentTrack({
        object: {
          orderItemId: orderId,
          note,
          amount,
          accountId,
          accountName,
          currency,
        },
      })
      // this.editPaidAmount({
      //   object: this.prices,
      //   successCallback: () => {
      //     this.$refs.modal.hide();
      //   },
      // })
    },
    onCancel() {
      // this.$refs.modal.hide();
      this.showTemplate = false
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      this.location = ''
      // Reset our form values
      // Trick to reset/clear native browser form validation state
    },
    // addNewDate() {
    //   const newPaymentTrack = {
    //     id: '',
    //     orderItemId: '',
    //     executorId: '',
    //     note: '',
    //     amount: 0,
    //     created: '',
    //     fullName: 'Executor',
    //   };

    //   this.form.forEach((item) => {
    //     item.orderItemPaymentTrackResponseDtos.push({ ...newPaymentTrack });
    //   });
    // },
    fillForm() {
      this.form = []
      for (let i = 0; i < this.getProbeDates.length; i++) {
        const date = this.getProbeDates[i].probeDate.substring(0, 10);
        const updatedItem = { ...this.getProbeDates[i], date };
        this.form.push(updatedItem);
      }
    },
    fillPrices() {
      this.prices = {
        ...this.getPrices,
      };
    },
    fillLocation() {
      this.location = {
        ...this.getOrderItemLocatioon,
      };
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY hh:mm')
    },
    removeProbeDate(probeDate) {
      if (probeDate.isNew == true) {
        const index = this.form.findIndex((item) => { return item.probeId === probeDate.probeId });
        if (index !== -1) {
          this.form.splice(index, 1);
        }
      } else {
        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this fitting date?', {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
          .then((value) => {
            if (value) {
              this.delete_probeDate({
                probeId: probeDate.probeId,
                successCallback: () => {
                  this.loadProbeDates(this.orderItemId);
                },
              });
            }
          })
          .catch((err) => {
            console.log('Error in msgBoxConfirm:', err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image{
  width: 100px;
  height: 80px;
  object-fit: cover;
  margin-right: 15px;
  cursor: pointer;
}
.failed2 {
  background-color: rgb(255, 95, 95);
  color: white !important;
  .hr {
  background-color: white;
min-width: 201px;
height: 1px;
/* UI Properties */
border: 0.5px solid white;
opacity: 0.74;
}
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: flex-end;
}
.form2 {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  gap: 10px;
}
.hr{
  background-color: grey;
min-width: 201px;
height: 1px;
/* UI Properties */
border: 0.5px solid #A5A5A5;
opacity: 0.34;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.headline {
  padding: 5px 10px;
  background-color: $base-color;
  color: $digit-white;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;
  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background-color: #6c757d;;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
@media only screen and (max-width: 1200px){
  .front-body {
  position: relative;
  .toraks {
    top: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    position: absolute;
    top: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    position: absolute;
    top: 125px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .beli {
    position: absolute;
    top: 155px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    position: absolute;
    top:249px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    position: absolute;
    bottom: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    position: absolute;
    bottom: 35px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    position: absolute;
    bottom: 5px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    position: absolute;
    top: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    position: absolute;
    top: 125px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    position: absolute;
    top: 155px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    position: absolute;
    top: 95px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    position: absolute;
    bottom: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    position: absolute;
    bottom: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    position: absolute;
    bottom: 35px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    position: absolute;
    bottom: 5px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background-color: #6c757d;;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    position: absolute;
    top: 126px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    position: absolute;
    top: 156px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    position: absolute;
    top: 192px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    position: absolute;
    top: 70px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    position: absolute;
    top: 215px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .supet {
    position: absolute;
    top: 97px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    position: absolute;
    top: 70px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    position: absolute;
    bottom: 100px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    position: absolute;
    top: 98px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
}
input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
    top: 195px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
    }
  }
}
</style>
